import React, { useEffect } from "react";
import reverseLogistics from "../../assets/images/reverseLogistics.jpg";
import '../../assets/css/style.css';
import Header from "../Common/Header";
import Footer from "../Common/Footer";

function ReverseLogistics(props) {

    useEffect(() => {
        document.title = "Reverse Logistics - Neelkanth Roadways"
    }, [])

    return (
        <React.Fragment>
            <Header />
            <section className="logic-provider-section service-section">
                <div className="content-left">
                    <h4>Reverse Logistics</h4>
                    <span>
                        Returns form an essential component of the supply chain processes; in fact the Supply Chain Operations Reference Model (SCOR) includes it in its key processes – Plan-Source-Make-Deliver-Return.
                        Goods that arrive in short quantity, damaged or those that do not conform to quality need to be sent back to their origin.
                        We treat such processes differently as they need -
                    </span>
                    {/* <span>This door-to-door pickup and delivery service is backed by 54 state-of-the-art air hubs. Air cargo requires a careful handling due to its subtle and time sensitive nature. A dedicated team of specialized air freight experts man these hubs 24x7. This team has expertise and experience in completing the various documentation formalities required for different product categories across different airlines travelling to different states.</span> */}
                    <div className="span-text">
                        <h4>We treat such processes differently as they need -</h4>
                        <ul className="list-content">
                            <li>Separate documentation procedure (Returns Invoice)</li>
                            <li>Specialized handling (repacking as in the original arrival state)</li>
                            <li>Returns authorization from the original consignor</li>
                            <li>Goods disposition decision</li>
                            <li>Approvals and authorization hierarchy</li>
                            <li>Revised documentation and taxation</li>
                            <li>Re-packing for road worthy shipment</li>
                        </ul>
                    </div>
                </div>
                <div className="image-right">
                    <img src={reverseLogistics} alt="" />
                </div>
            </section>
            <Footer />
        </React.Fragment>
    );
}

export default ReverseLogistics;