import './App.css';
import Home from './Components/Home/Home';
import About from './Components/About/About';
import ReverseLogistics from './Components/Services/ReverseLogistics';
import ExpressDistribution from './Components/Services/ExpressDistribution';
import InventoryManagement from './Components/Services/InventoryManagement';
import WarehousingDistribution from './Components/Services/WarehousingDistribution'
import Connectivity from './Components/Network/Connectivity';
import LogisticsParks from './Components/Network/LogisticsParks';
import ApparelsAndLifestyle from './Components/Industries/ApparelsAndLifestyle';
import Automotive from './Components/Industries/Automotive';
import AutoPart from './Components/Industries/AutoPart';
import Packaging from './Components/Industries/Packaging';
import Quote from './Components/Home/Quote';
import Contact from './Components/Contact/Contact';
import GSTInfo from './Components/GSTPages/GSTInfo';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path="/"
            component={Home}
          />
          <Route
            exact
            path="/about-us"
            component={About}
          />
          <Route
            exact
            path="/express-distribution"
            component={ExpressDistribution}
          />
          <Route
            exact
            path="/inventory-management"
            component={InventoryManagement}
          />
          <Route
            exact
            path="/reverse-logistics"
            component={ReverseLogistics}
          />
          <Route
            exact
            path="/warehousing-distribution"
            component={WarehousingDistribution}
          />
          <Route
            exact
            path="/automotive"
            component={Automotive}
          />
          <Route
            exact
            path="/packaging"
            component={Packaging}
          />
          <Route
            exact
            path="/auto-part"
            component={AutoPart}
          />
          <Route
            exact
            path="/connectivity"
            component={Connectivity}
          />
          <Route
            exact
            path="/send-quote"
            component={Quote}
          />
          <Route
            exact
            path="/contact-us"
            component={Contact}
          />
          <Route
            exact
            path="/gst-info"
            component={GSTInfo}
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
}
export default App;
