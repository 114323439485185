import React from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import HomePageBanner from './HomePageBanner';
import HomeAbout from './HomeAbout';
import Services from './Services';
import Clients from './Clients';

function Home(props) {

    return (
        <React.Fragment>
            <Header />
            <HomePageBanner />
                <HomeAbout />
                <Services />
                <Clients />
                {/* <Testimonials/> */}
            <Footer />
        </React.Fragment>
    );
}

export default Home;