import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import banner1 from '../../assets/images/banner1.webp';
// import banner2 from '../../assets/images/banner2.webp';
// import banner3 from '../../assets/images/banner3.jpg';
// import banner4 from '../../assets/images/banner4.jpg';
import banner1 from '../../assets/images/ex_4.webp';
import banner2 from '../../assets/images/ex_2.webp';
import banner3 from '../../assets/images/ex_3.webp';
import banner4 from '../../assets/images/ex_1.webp';

function HomePageBanner(props) {

    const homePageBannerSettings = {
        infinite: true,
        speed: 400,
        slidesToShow: 1,
        slidesToScroll: 1,
        items: 1,
        autoplay: true
    };

    return (
        <div className="header-banner">
            <Slider {...homePageBannerSettings} >
                <div>
                    <div style={{ backgroundImage: 'linear-gradient(180deg, rgba(10, 27, 43, 0.47), rgba(10, 27, 43, 0.98) 100%, #33a041), url(' + banner1 + ')' }} className="banner-content-wrapper">
                        {/* <div style={{ backgroundImage: 'url(' + banner1 + ')' }} className="banner-content-wrapper"> */}
                        {/* <img src={banner1} alt="" /> */}
                        <div className="banner-content">
                            <h2>Best in Logistics and Warehousing</h2>
                            <div className="bottom-buttons">
                                {/* <a href="/send-quote">Get Quote</a> */}
                                {/* <a href="#">Track Your Package</a> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{ backgroundImage: 'linear-gradient(180deg, rgba(10, 27, 43, 0.47), rgba(10, 27, 43, 0.98) 100%, #33a041), url(' + banner2 + ')' }} className="banner-content-wrapper">
                        {/* <div style={{ backgroundImage: 'url(' + banner2 + ')' }} className="banner-content-wrapper"> */}
                        {/* <img src={banner2} alt="" /> */}
                        <div className="banner-content">
                            <h2>Best in Logistics and Warehousing</h2>
                            <div className="bottom-buttons">
                                {/* <a href="/send-quote">Get Quote</a> */}
                                {/* <a href="#">Track Your Package</a> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{ backgroundImage: 'linear-gradient(180deg, rgba(10, 27, 43, 0.47), rgba(10, 27, 43, 0.98) 100%, #33a041), url(' + banner3 + ')' }} className="banner-content-wrapper">
                        {/* <div style={{ backgroundImage: 'url(' + banner3 + ')' }} className="banner-content-wrapper"> */}
                        {/* <img src={banner3} alt="" /> */}
                        <div className="banner-content">
                            <h2>Best in Logistics and Warehousing</h2>
                            <div className="bottom-buttons">
                                {/* <a href="/send-quote">Get Quote</a> */}
                                {/* <a href="#">Track Your Package</a> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{ backgroundImage: 'linear-gradient(180deg, rgba(10, 27, 43, 0.47), rgba(10, 27, 43, 0.98) 100%, #33a041), url(' + banner4 + ')' }} className="banner-content-wrapper">
                        {/* <div style={{ backgroundImage: 'url(' + banner4 + ')' }} className="banner-content-wrapper"> */}
                        {/* <img src={banner4} alt="" /> */}
                        <div className="banner-content">
                            <h2>Best in Logistics and Warehousing</h2>
                            <div className="bottom-buttons">
                                {/* <a href="/send-quote">Get Quote</a> */}
                                {/* <a href="#">Track Your Package</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </Slider>
        </div >
    )
}

export default HomePageBanner;