import React from "react";
import logo from "../../assets/images/logo.png";
import apparelsAndLifestyle from "../../assets/images/apparels-and-lifestyle.jpg";
import '../../assets/css/style.css';

function LogisticsParks(props) {
    return (
        <React.Fragment>
            <div class="header">
                <div class="logo">
                    <a href="/"><img src={logo} alt="" /></a>
                </div>
                <ul>
                    <li>
                        <a href="/">GST Support</a>
                        <div class="dropdown">
                            <ul>
                                <li><a href="/">Create GST Invoice</a></li>
                                <li><a href="/">Download GST Invoice</a></li>
                                <li><a href="/">GST advantage with Safexpress</a></li>
                                <li><a href="/">Call back request</a></li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <a href="/">Services</a>
                        <div class="dropdown">
                            <ul>
                                <li><a href="/safe-returns">Safe Returns</a></li>
                                <li><a href="/reverse-logistics">Reverse Logistics</a></li>
                                <li><a href="/vendor-management-inventory">Vendor Management Inventory</a></li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <a href="/">Industries</a>
                        <div class="dropdown">
                            <ul>
                                <li><a href="/apparels-lifestyle">Logistics Parks</a></li>
                            </ul>
                        </div>
                    </li>
                    {/* <li>
                        <a href="/">Support</a>
                    </li> */}
                    <li>
                        <a href="/">Network</a>
                        <div class="dropdown">
                            <ul>
                                <li><a href="/logistics-parks">Logistics Parks</a></li>
                                <li><a href="/connectivity">Connectivity</a></li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <a href="#about-us">About Us</a>
                    </li>
                    <li>
                        <a href="#contact-us">Contact Us</a>
                    </li>
                </ul>
            </div>        
            {/* <div class="header-banner">
                <img src="./images/slider-images.jpg" alt=""/>
                <div class="banner-content">
                    <h2>Neelkanth Corporation of India</h2>
                    <div class="bottom-buttons">
                        <a href="#">Check Service Availability</a>
                        <a href="#">Track Your Packages</a>
                    </div>
                </div>
            </div> */}
            <section class="logic-provider-section service-section">
                <div class="content-left">
                    <h4>Apparels & Lifestyle</h4>
                    <span>
                        We drastically reduce the transit time across the supply chain to ensure your products reach the shelves on time for the season, sale events or even heavy shopping days like Sunday.
                        The apparel industry is characterized by
                    </span>
                    <span>
                        Safe Returns
                        The industry is marked by a high percentage of returns - be they of damaged or unsold products. These need to find their way back to a re-distribution or a disposition warehouse. Our reverse logistics services are convenient, single point solutions for your retailers. It includes assessing the products to be returned, assisting disposition decisions, repacking and finally returning the shipment.
                    </span>
                    <span>
                        Stock2Shelf
                        Our 'Stock2Shelf' service is a first of its kind - it comprises of determining the need at a retail store, assessing the stock available at the feeder warehouse, delivering the stock as required within the given time lines. The service has been especially successful in retail outlets operating out of mega malls where customer foot-falls are high. The outlets benefit in not having to store high inventories in their back rooms, the distribution company does not have to manage and own warehouses near the point of consumption, and the customers always have their brands, styles, colors and size on their shelves. This win-win-win service entails precise last-mile delivery expertise that we have developed over the years serving various retail outlet formats. 'Stock2Shelf' provides a comprehensive supply chain service for malls and shopping outlets, which includes movement of retail and lifestyle goods, quality inspection, professional packaging, security clearance at mall, unpacking services and reverse pick-ups even on Sundays and holidays.
                    </span>
                    {/* <span>This door-to-door pickup and delivery service is backed by 54 state-of-the-art air hubs. Air cargo requires a careful handling due to its subtle and time sensitive nature. A dedicated team of specialized air freight experts man these hubs 24x7. This team has expertise and experience in completing the various documentation formalities required for different product categories across different airlines travelling to different states.</span> */}
                    <div class="span-text">
                        <h4>KEY HIGHLIGHTS OF SAFEXPRESS LOGISTIC PARKS</h4>
                        <ul class="list-content">
                            <li>Large, fully secure logistics parks built close to National Highways</li>
                            <li>24x7 @ 365 days operations</li>
                            <li>Secured campus with surveillance and security systems provide 24x7 security</li>
                            <li>Continuous truck-height loading platform for proper material handling</li>
                            <li>60-80 feet concrete docking area to provide a dust-free environment</li>
                            <li>Hundreds of docks ensuring minimal truck wait time</li>
                            <li>Ergonomically designed cross-dock platforms</li>
                            <li>High quality heavy-duty floor (point loads of 4 MT / 6 MT per square meter)</li>
                        </ul>
                    </div>
                </div>
                <div class="image-right">
                    <img src={apparelsAndLifestyle} alt=""/>
                </div>
            </section>
            <section class="contact-us" id="contact-us">
                <div class="footer-logo">
                    <img src={logo} alt="" />
                </div>
                <div class="contact-details">
                    <ul>
                        {/* <li>
                            <h4><span><i class="fa fa-envelope-o" aria-hidden="true"></i></span></h4>
                            <p>abc@gmail.com</p>
                        </li> */}
                        <li>
                            <h4><span><i class="fa fa-phone" aria-hidden="true"></i></span></h4>
                            <p>9310827822, 9350827821</p>
                        </li>
                        <li>
                            <h4><span><i class="fa fa-map-marker" aria-hidden="true"></i></span></h4>
                            <p>Behind Fire Station, Opp. Saraswati Enclave, Sector-37, Gurugram, Haryana 122001</p>
                        </li>
                    </ul>
                </div>
            </section>
        </React.Fragment>
    );
}

export default LogisticsParks;