import React, { useEffect } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import '../../assets/css/style.css';
import haridwarOffice from "../../assets/images/haridwar.jpg"
import neemranaOffice from "../../assets/images/neemrana.jpg"
import gujaratOffice from "../../assets/images/gujarat.jpg"
import junagadhOffice from "../../assets/images/junagadh.jpg"
import sanandOffice from "../../assets/images/sanand.jpg"
import ahmedabadOffice from "../../assets/images/ahmedabad.jpg"
import jaipurOffice from "../../assets/images/hawamahal.jpg"
import ludhianaOffice from "../../assets/images/ludhiana.jpg"
import faridabadOffice from "../../assets/images/faridabad.jpg"
// import team2 from "../../assets/images/team2.jpg"
// import team3 from "../../assets/images/team3.jpg"

function Connectivity(props) {

    useEffect(() => {
        document.title = "Network - Neelkanth Roadways"
    }, [])

    const googleMapDirection = (lattitude, longitude) => {
        console.log("here :", lattitude, longitude)
        window.open(`https://maps.google.com?q=${lattitude},${longitude}`);
    };

    return (
        <React.Fragment>
            <Header />
            <section className="service-section">
                <h2 style={{ textAlign: "center" }}>Our Zonal Offices</h2>
                <div className="row">
                    <div className="column">
                        <div className="network-card">
                            <img style={{ width: "100%", minHeight: "257px" }} src={haridwarOffice} alt="" />
                            <div className="about-container">
                                <h2>HARIDWAR</h2>
                                {/* <p className="team-title">CEO & Founder</p> */}
                                <p className="three-line-text">PLOT NO. 56/57, NEAR PNB BANK, DWARKA VIHAR COLONY, BAHADRABAD, HARIDWAR</p>
                                <p>MOB: 7060011217, 8979644597</p>
                                <p><button className="contact-button" onClick={() => googleMapDirection(29.9171933, 78.0420423)} >Get Direction</button></p>
                            </div>
                        </div>
                    </div>

                    <div className="column">
                        <div className="network-card">
                            <img style={{ width: "100%", minHeight: "257px" }} src={neemranaOffice} alt="" />
                            <div className="about-container">
                                <h2>NEEMRANA</h2>
                                {/* <p className="team-title">CEO & Founder</p> */}
                                <p className="three-line-text">BEHIND LIBERTY CO, RICO IND AREA, NEEMRANA</p>
                                <p>MOB: 9050088835</p>
                                <p><button className="contact-button" onClick={() => googleMapDirection(27.9748899, 76.362182)} >Get Direction</button></p>
                            </div>
                        </div>
                    </div>

                    <div className="column">
                        <div className="network-card">
                            <img style={{ width: "100%", minHeight: "257px" }} src={gujaratOffice} alt="" />
                            <div className="about-container">
                                <h2>HALOL</h2>
                                {/* <p className="team-title">CEO & Founder</p> */}
                                <p className="three-line-text">GF-10, NEELKANTH COMPLEX, GIDC HALOL, GUJARAT</p>
                                <p>MOB: 7434867822, 9355487822</p>
                                <p><button className="contact-button" onClick={() => googleMapDirection(22.532094, 73.4603239)} >Get Direction</button></p>
                            </div>
                        </div>
                    </div>

                    <div className="column">
                        <div className="network-card">
                            <img style={{ width: "100%", minHeight: "257px" }} src={junagadhOffice} alt="" />
                            <div className="about-container">
                                <h2>VITHALAPUR</h2>
                                {/* <p className="team-title">CEO & Founder</p> */}
                                <p className="three-line-text">SHARMA COMPLEX, KADDI ROAD, VITHALAPUR</p>
                                <p>MOB: 9355487522</p>
                                <p><button className="contact-button" onClick={() => googleMapDirection(23.3637511, 72.0521125)} >Get Direction</button></p>
                            </div>
                        </div>
                    </div>

                    <div className="column">
                        <div className="network-card">
                            <img style={{ width: "100%", minHeight: "257px" }} src={sanandOffice} alt="" />
                            <div className="about-container">
                                <h2>SANAND</h2>
                                {/* <p className="team-title">CEO & Founder</p> */}
                                <p className="three-line-text">NANA CHILODA, NH NO-8 TO NARODA ROAD, SANAND</p>
                                <p>MOB: 8619279672</p>
                                <p><button className="contact-button" onClick={() => googleMapDirection(23.1011161, 72.6641428)} >Get Direction</button></p>
                            </div>
                        </div>
                    </div>

                    <div className="column">
                        <div className="network-card">
                            <img style={{ width: "100%", minHeight: "257px" }} src={ahmedabadOffice} alt="" />
                            <div className="about-container">
                                <h2>AHMEDABAD</h2>
                                {/* <p className="team-title">CEO & Founder</p> */}
                                <p className="three-line-text">PLOT NO-34, NAROL CHOKADI, TPT NAGAER, ASLALI, AHMEDABAD</p>
                                <p>MOB: 9227555562</p>
                                <p><button className="contact-button" onClick={() => googleMapDirection(22.9695485, 72.5824361)} >Get Direction</button></p>
                            </div>
                        </div>
                    </div>

                    <div className="column">
                        <div className="network-card">
                            <img style={{ width: "100%", minHeight: "257px" }} src={ludhianaOffice} alt="" />
                            <div className="about-container">
                                <h2>LUDHIANA</h2>
                                {/* <p className="team-title">CEO & Founder</p> */}
                                <p className="three-line-text">PLOT NO-22, GALI NO.4, HIRA NAGAR, LUDHIANA</p>
                                <p>MOB: 9690758188</p>
                                <p><button className="contact-button" onClick={() => googleMapDirection(30.9123739, 75.8676436)} >Get Direction</button></p>
                            </div>
                        </div>
                    </div>

                    <div className="column">
                        <div className="network-card">
                            <img style={{ width: "100%", minHeight: "257px" }} src={jaipurOffice} alt="" />
                            <div className="about-container">
                                <h2>JAIPUR</h2>
                                {/* <p className="team-title">CEO & Founder</p> */}
                                <p className="three-line-text">PLOT NO-32, ROAD NO.14, VKI, JAIPUR</p>
                                <p>MOB: 8077963403</p>
                                <p><button className="contact-button" onClick={() => googleMapDirection(26.9937653, 75.7586293)} >Get Direction</button></p>
                            </div>
                        </div>
                    </div>
                    <div className="column">
                        <div className="network-card">
                            <img style={{ width: "100%", minHeight: "257px" }} src={faridabadOffice} alt="" />
                            <div className="about-container">
                                <h2>FARIDABAD</h2>
                                <p className="three-line-text">3D/41B, B.P ROAD, NEW INDUSTRIAL TOWN, FARIDABAD</p>
                                <p>MOB: 9625391213</p>
                                <p><button className="contact-button" onClick={() => googleMapDirection(28.3751644, 77.3045676)} >Get Direction</button></p>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <Footer />
        </React.Fragment>
    );
}

export default Connectivity;