import React, { useEffect } from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import '../../assets/css/style.css';

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

const StyledTableCell = withStyles((theme) => ({
    root: {
        borderRightWidth: 1,
        borderRightColor: theme.palette.grey[300],
        borderRightStyle: "solid",
    },
    head: {
        backgroundColor: '#696969',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 15,
    },
}))(TableCell);

const StyledTableDataCell = withStyles((theme) => ({
    root: {
        borderRightWidth: 1,
        borderRightColor: theme.palette.grey[300],
        borderRightStyle: "solid",
    }
}))(TableCell);

function createData(sr, state, gst, tax, tp, im, om, is, tf, stw) {
    return { sr, state, gst, tax, tp, im, om, is, tf, stw };
}

const rows = [
    createData(1, 'Andra Pradesh', 'Yes', 'No', 'Yes', 'eWaybill for value > 50000', 'eWaybill', 'No', 'TP Required', 'http://www.apct.gov.in/'),
    createData(2, 'Arunachal Pradesh', 'Yes', 'No', 'No', 'eWaybill', 'eWaybill', 'No', 'No', 'http://www.arunachalpradesh.nic.in/'),
    createData(3, 'Assam', 'Yes', 'Yes', 'Yes', 'eWaybill', 'eWaybill', 'No', 'Yes', 'http://www.tax.assam.gov.in/'),
    createData(4, 'Bihar', 'Yes', 'N.A', 'Yes', 'eWaybill D-IX', 'eWaybill-X', 'eWaybill-VII', 'Yes', 'http://www.biharcommercialtax.gov.in/'),
    createData(5, 'Chhattisgarh', 'Yes', 'No', 'No', 'No', 'No', 'No', '-', 'http://www.comtax.cg.nic.in/'),

    createData(6, 'Daman & Diu', 'Yes', 'No', 'No', 'No', 'No', 'No', '-', 'http://www.daman.nic.in/'),
    createData(7, 'Delhi', 'Yes', 'No', 'No', 'No', 'No', 'No', '-', 'http://www.dvat.gov.in/'),
    createData(8, 'Goa', 'Yes', 'No', 'No', 'No', 'No', 'No', '-', 'http://www.goacomtax.gov.in/'),
    createData(9, 'Gujrat', 'Yes', 'No', 'Yes', 'Form 403', 'Form 402', 'Form 402', 'Transits pass in Form 405', 'http://www.commercialtax.gujarat.gov.in/'),
    createData(10, 'Haryana', 'Yes', 'No', 'No', 'No', 'No', 'No', '-', 'http://www.haryanatax.gov.in/'),

    createData(11, 'Himachal Pradesh', 'Yes', 'Yes', 'No', 'No', 'No', 'No', 'Form XXVI A has to generate before entry in HP', 'http://www.hptax.gov.in/'),
    createData(12, 'Jammu & Kashmir', 'Yes', 'Toll Tax', 'No', 'No', 'No', 'No', '-', 'http://jkcomtax.gov.in/'),
    createData(13, 'Jharkhand', 'Yes', 'No', 'No', 'No', 'No', 'No', 'No', 'http://www.jharkhandcomtax.gov.in/'),
    createData(14, 'Karnataka', 'Yes', 'Yes', 'Yes', 'eWaybill for value > 50000', 'eWaybill for value > 50000', 'eWaybill for value > 50000', 'Yes', 'http://www.ctax.kar.nic.in/'),
    createData(15, 'Kerala', 'Yes', 'No', 'No', 'No', 'No', 'No', 'No', 'http://www.keralataxes.org/'),

    createData(16, 'Madhya Pradesh', 'Yes', 'No', 'No', 'No', 'No', 'No', 'No', 'http://www.mptax.net/'),
    createData(17, 'Maharashtra', 'Yes', 'No', 'No', 'No', 'No', 'No', 'No', 'http://www.mahavat.gov.in/'),
    createData(18, 'Manipur', 'Yes', 'No', 'No', 'eWaybill', 'eWaybill', 'No', 'No', 'http://www.manipurvat.gov.in/'),
    createData(19, 'Meghalaya', 'Yes', 'No', 'No', 'eWaybill', 'eWaybill', 'No', 'No', 'http://www.megvat.gov.in/forms.htm'),
    createData(20, 'Mizoram', 'Yes', 'No', 'No', 'No', 'No', 'No', 'No', 'http://www.mizoram.nic.in/'),

    createData(21, 'Nagaland', 'Yes', 'No', 'No', 'No', 'No', 'No', 'No', 'http://nagalandtax.nic.in/'),
    createData(22, 'Orissa', 'Yes', 'No', 'No', 'No', 'No', 'No', 'No', 'https://odishatax.gov.in/'),
    createData(23, 'Puducherry', 'Yes', 'No', 'No', 'No', 'No', 'No', 'No', 'http://gst.puducherry.gov.in/'),
    createData(24, 'Punjab', 'Yes', 'No', 'No', 'No', 'No', 'No', 'No', 'https://www.pextax.com/'),
    createData(25, 'Rajasthan', 'Yes', 'No', 'No', 'No', 'No', 'No', 'No', 'http://rajtax.gov.in/vatweb/'),

    createData(26, 'Sikkim', 'Yes', 'No', 'N.A', 'eWaybill', 'eWaybill', 'No', 'No', 'http://www.sikkimtax.gov.in/'),
    createData(27, 'Telangana', 'Yes', 'No', 'No', 'eWaybill for value > 50000', 'eWaybill for value > 50000', 'eWaybill for value > 50000', 'No', 'http://www.tgct.gov.in/'),
    createData(28, 'Tamil Nadu', 'Yes', 'No', 'No', 'No', 'No', 'No', 'No', 'http://www.tnvat.gov.in/'),
    createData(29, 'Uttara Khand', 'Yes', 'No', 'No', 'No', 'No', 'No', 'No', 'www.comtax.uk.gov.in'),
    createData(30, 'Uttar Pradesh', 'Yes', 'No', 'Yes', 'eWaybill 01 for value > 50000', 'eWay Bill 02 for Value > 50000', 'eWay Bill 02 for Value > 50000', 'TDF 1', 'http://comtax.uk.gov.in/'),
    createData(31, 'West Bengal', 'Yes', 'No', 'No', 'Form 50A', 'Form 51A', 'No', 'No', 'http://www.wbcomtax.nic.in/Act_Rule_Schedule_Form/vatforms.htm')
];

function GSTInfo(props) {

    const classes = useStyles();

    useEffect(() => {
        document.title = "Network - Neelkanth Roadways"
    }, [])

    return (
        <React.Fragment>
            <Header />
            <section className="service-section">
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell rowSpan={2}>SrNo.</StyledTableCell>
                                <StyledTableCell align="center" rowSpan={2} className="gst-state-name-column">State</StyledTableCell>
                                <StyledTableCell align="center" rowSpan={2}>GST Invoice</StyledTableCell>
                                <StyledTableCell align="center" rowSpan={2}>Entry Tax</StyledTableCell>
                                <StyledTableCell align="center" rowSpan={2}>T.P</StyledTableCell>
                                <StyledTableCell align="center" colSpan={4} >Types of Form Required</StyledTableCell>
                                <StyledTableCell align="center" rowSpan={2}>Sale Tax Website</StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell align="center">Incoming Material</StyledTableCell>
                                <StyledTableCell align="center">Outgoing Material</StyledTableCell>
                                <StyledTableCell align="center">Intra State</StyledTableCell>
                                <StyledTableCell align="center">Transit Form</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow key={row.sr}>
                                    <StyledTableDataCell>{row.sr}</StyledTableDataCell>
                                    <StyledTableDataCell align="left">{row.state}</StyledTableDataCell>
                                    <StyledTableDataCell align="center">{row.gst}</StyledTableDataCell>
                                    <StyledTableDataCell align="center">{row.tax}</StyledTableDataCell>
                                    <StyledTableDataCell align="center">{row.tp}</StyledTableDataCell>
                                    <StyledTableDataCell align="center">{row.im}</StyledTableDataCell>
                                    <StyledTableDataCell align="center">{row.om}</StyledTableDataCell>
                                    <StyledTableDataCell align="center">{row.is}</StyledTableDataCell>
                                    <StyledTableDataCell align="center">{row.tf}</StyledTableDataCell>
                                    <StyledTableDataCell align="left"><a target="_blank" href={row.stw} >{row.stw}</a></StyledTableDataCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </section>
            <Footer />
        </React.Fragment>
    );
}

export default GSTInfo;