import React, { useEffect } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import '../../assets/css/style.css';

function Contact(props) {

    useEffect(() => {
        document.title = "Contact Us"
    }, [])

    return (
        <React.Fragment>
            <Header />
            <section className="service-section">
                <div className="contact-in" >
                    <div className="contact-map" >
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7016.277571438033!2d76.99093219448615!3d28.4452324296243!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1793b1d37777%3A0x42dd780a1799da33!2sSaraswati%20Enclave%2C%20Sector%2010B%2C%20Sector%2037%2C%20Gurugram%2C%20Haryana%20122004!5e0!3m2!1sen!2sin!4v1616424283421!5m2!1sen!2sin" width="100%" height="auto" style={{ "border": "0px" }} allowFullScreen="" loading="lazy"></iframe>
                    </div>
                    <div className="contact-form" >
                        <h1>Contact Us</h1>
                        <form>
                            <input type="text" placeholder="Name" className="contact-form-txt" required></input>
                            <input type="text" placeholder="Email" className="contact-form-txt" required></input>
                            <textarea placeholder="Message" className="contact-form-textarea" required></textarea>
                            <input type="submit" name="Submit" className="contact-form-btn" ></input>
                        </form>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>
    );
}

export default Contact;