import React from 'react';
import bodyMarkerBanner from '../../assets/images/body-marker-banner.jpg';

function HomeAbout(props) {
    return (
        <section className="logic-provider-section" id="about-us">
            <h2 className="main-heading">
                About Us
            </h2>
            <div className="about-us-section">
                <div className="content-left">
                    <h4>India's best logistic and warehousing provider</h4>
                    <span>
                        Set up as a One-truck activity in 1992, Neelkanth rapidly shifted gears to grow into one of the Reliable surface logistics and road transportation companies in Indian Auto Industries.

                        <br></br>With a network that spans 22 stations countrywide and a workforce of over 200 highly trained people, Neelkanth is reputed for its prompt, reliable service and strict adherence to delivery schedules that facilitate movement of cargo worth Rs.100 Million annually. The Company today has more than 100+ satisfied customers in Auto & Textile Industries, which include multinationals, public and private sector organizations and small-scale industries as well as the trading community.

                        <br></br>The Company is steadily delivering on its promise of providing the swift service Investment in IT and state-of-the art tracking systems, coupled with containerization of its entire fleet, help Neelkanth to maintain distinctive edge over competition while simultaneously offering enhanced and value-added services to the industry.
                    </span>
                </div>
                <div className="image-right">
                    <img src={bodyMarkerBanner} alt="" />
                </div>
            </div>
        </section>
    )
}

export default HomeAbout;