import React, { useEffect } from "react";
import inventoryManage from "../../assets/images/inventoryManagement.jpg";
import '../../assets/css/style.css';
import Header from "../Common/Header";
import Footer from "../Common/Footer";

function InventoryManagement(props) {

    useEffect(() => {
        document.title = "Inventory Management - Neelkanth Roadways"
    }, [])

    return (
        <React.Fragment>
            <Header />
            <section class="logic-provider-section service-section">
                <div class="content-left">
                    <h4>3PL Inventory Management</h4>
                    <span>
                        Our engagement in ‘warehousing’ begins from the planning stage, which includes identification of strategic locations for your warehouses. We further work with our customers closely to design their rack layout, as well as to decide the optimal stocking levels.
                    </span>
                    <span>Warehousing is one the most important aspect of Third Party Logistics, commonly known as 3PL. We work with a world-class warehouse management system that records every transaction and maintains data integrity. Our state-of-the-art warehouse management systems monitor and control all critical warehouse processes. </span>
                    <div class="span-text">
                        <h4>Salient Features</h4>
                        <ul class="list-content">
                            <li>Highly configurable lot handling and shelf life handling (for FMCG and food items)</li>
                            <li>Each piece scanning for tracking movement of inventory within and between warehouses</li>
                            <li>Kitting and VAS (Value-Added Services)</li>
                            <li>Highly configurable rules and strategies for put-away, replenishment, picking, kitting and VAS</li>
                            <li>Integration with Warehouse Control Systems (ASRS (Automated Storage and Retrieval Systems), Carousel systems)</li>
                            <li>Intensive use of technology</li>
                        </ul>
                    </div>
                </div>
                <div class="image-right">
                    <img src={inventoryManage} alt="" />
                </div>
            </section>
            <Footer />
        </React.Fragment>
    );
}

export default InventoryManagement;