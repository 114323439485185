import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import bonyLogo from '../../assets/images/bonyLogo.png';
import heroLogo from '../../assets/images/heroLogo.png';
import meenakshiLogo from '../../assets/images/meenakshi-logo.png';
import munjalLogo from '../../assets/images/munjalAuto.png';
import remsonsLogo from '../../assets/images/remsonsLogo.png';
import satyamLogo from '../../assets/images/satyamAuto.jpg';
import tirupatiLogo from '../../assets/images/tirupati logo.png';
import agLogo from '../../assets/images/ag.png';
import endurenceLogo from '../../assets/images/endurence.png';
import lumaxLogo from '../../assets/images/lumax.png';
import lifelongLogo from '../../assets/images/lifelong.png';
import varrocLogo from '../../assets/images/varroc.jpg';

function Clients(props){

    const clientSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        centerMode: true,
        slidesToScroll: 2,
        items: 2,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    centerMode: false,
                    items: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                    items: 1
                }
            }
        ]
    };

    return (
        <section className="our-services-section clientSlider">
            <h2 className="main-heading">
                Our Clients
            </h2>
            <Slider {...clientSettings}>
                <img className="clients-logo" src={bonyLogo} alt="" />
                <img src={remsonsLogo} alt="" />
                <img src={heroLogo} alt="" />
                <img src={meenakshiLogo} alt="" />
                <img src={munjalLogo} alt="" />
                <img src={satyamLogo} alt="" />
                <img src={tirupatiLogo} alt="" />
                <img src={agLogo} alt="" />
                <img src={endurenceLogo} alt="" />
                <img src={lumaxLogo} alt="" />
                <img src={lifelongLogo} alt="" />
                <img src={varrocLogo} alt="" />
            </Slider>
        </section>
    )
}

export default Clients;