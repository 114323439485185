import React from 'react';
import warehousingImage from '../../assets/images/warehousing.jpg';
import distributionImage from '../../assets/images/distribution.webp';
import inventoryManageImage from '../../assets/images/inventoryManage.jpg';
import reverseLogistics from '../../assets/images/reverseLogistics.jpg'

function Services(props) {
    return (
        <section className="our-services-section">
            <h2 className="main-heading">
                Our Services
            </h2>
            <div className="card-container">
                <div className="services-box">
                    <div className="image-wrapper">
                        <img src={distributionImage} alt="" />
                    </div>
                    <div className="box-content">
                        <h4>Express Distribution</h4>
                        <a href="/express-distribution">More Details</a>
                    </div>
                </div>
                <div className="services-box">
                    <div className="image-wrapper">
                        <img src={inventoryManageImage} alt="" />
                    </div>
                    <div className="box-content">
                        <h4>3PL Inventory Management</h4>
                        <a href="/inventory-management">More Details</a>
                    </div>
                </div>
                <div className="services-box">
                    <div className="image-wrapper">
                        <img src={reverseLogistics} alt="" />
                    </div>
                    <div className="box-content">
                        <h4>Reverse Logistics</h4>
                        <a href="/inventory-management">More Details</a>
                    </div>
                </div>
                <div className="services-box">
                    <div className="image-wrapper">
                        <img src={warehousingImage} alt="" />
                    </div>
                    <div className="box-content">
                        <h4>Warehousing and Distribution</h4>
                        <a href="/warehousing-distribution">More Details</a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Services;