import React, { useEffect } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import '../../assets/css/style.css';
import quoteImage from "../../assets/images/quote.png";

function Quote(props) {

    useEffect(() => {
        document.title = "Get Quote"
    }, [])

    return (
        <React.Fragment>
            <Header />
            <section className="service-section">
                <div className="get-quote" >
                    {/* <div className="quote-img" >
                        <img src={quoteImage} alt="" />
                    </div> */}
                    <div className="quote-form" >
                        <form>
                            <h1>Request For Quote</h1>
                            <input type="text" placeholder="Name" className="quote-form-txt" required></input>
                            <input type="text" placeholder="Business Name" className="quote-form-txt" required></input>
                            <input type="text" placeholder="Email" className="quote-form-txt" required></input>
                            <input type="tel" placeholder="Phone Number" className="quote-form-txt" required></input>
                            {/* <label className="quote-form-label" htmlFor="cars">Which type of service is required?</label> */}
                            <select className="quote-form-txt" name="service" id="service">
                                <option value="" disabled selected>Which type of service is required?</option>
                                <option value="ptl">PTL (Part Truck Load)</option>
                                <option value="lcl">LCL</option>
                                <option value="ftl">FTL</option>
                                <option value="train">Train</option>
                                <option value="air">Air</option>
                            </select>
                            <textarea placeholder="Description" className="quote-form-textarea" required></textarea>
                            <input type="submit" name="Submit" className="quote-form-btn" ></input>
                        </form>
                    </div>
                    <div className="cl"></div>
                </div>
            </section>
            <Footer />
        </React.Fragment>
    );
}

export default Quote;