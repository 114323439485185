import React, { useEffect } from "react";
import autoParts from "../../assets/images/autoParts.jpg";
import '../../assets/css/style.css';
import Header from "../Common/Header";
import Footer from "../Common/Footer";

function LogisticsParks(props) {

    useEffect(() => {
        document.title = "Auto Part - Neelkanth Roadways"
    }, [])

    return (
        <React.Fragment>
            <Header />
            {/* <div class="header-banner">
                <img src="./images/slider-images.jpg" alt=""/>
                <div class="banner-content">
                    <h2>Neelkanth Corporation of India</h2>
                    <div class="bottom-buttons">
                        <a href="#">Check Service Availability</a>
                        <a href="#">Track Your Packages</a>
                    </div>
                </div>
            </div> */}
            <section class="logic-provider-section service-section">
                <div class="content-left">
                    <h4>Auto Part</h4>
                    <span>
                        A large part of country economy depends on Automobile sector which in turn depends on auto parts. Moving auto parts is a challenging task as we need to make sure we provide 100% accuracy.
                    </span>
                    {/* <span>This door-to-door pickup and delivery service is backed by 54 state-of-the-art air hubs. Air cargo requires a careful handling due to its subtle and time sensitive nature. A dedicated team of specialized air freight experts man these hubs 24x7. This team has expertise and experience in completing the various documentation formalities required for different product categories across different airlines travelling to different states.</span> */}
                    <div class="span-text">
                        {/* <h4>KEY HIGHLIGHTS OF SAFEXPRESS LOGISTIC PARKS</h4>
                        <ul class="list-content">
                            <li>Large, fully secure logistics parks built close to National Highways</li>
                            <li>24x7 @ 365 days operations</li>
                            <li>Secured campus with surveillance and security systems provide 24x7 security</li>
                            <li>Continuous truck-height loading platform for proper material handling</li>
                            <li>60-80 feet concrete docking area to provide a dust-free environment</li>
                            <li>Hundreds of docks ensuring minimal truck wait time</li>
                            <li>Ergonomically designed cross-dock platforms</li>
                            <li>High quality heavy-duty floor (point loads of 4 MT / 6 MT per square meter)</li>
                        </ul> */}
                    </div>
                </div>
                <div class="image-right">
                    <img src={autoParts} alt="" />
                </div>
            </section>
            <Footer />
        </React.Fragment>
    );
}

export default LogisticsParks;