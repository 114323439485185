import React from "react";

function Footer(props) {
    return (
        <section className="contact-us footer" id="contact-us">
            <div className="contact-details">
                <ul>
                    {/* <li>
                        <h4><span><i className="fa fa-envelope-o" aria-hidden="true"></i></span></h4>
                        <p>abc@gmail.com</p>
                    </li> */}
                    <li>
                        <h4><span><i className="fa fa-phone" aria-hidden="true"></i></span></h4>
                        <p> 0124-2334639, 9310827822, 9350827821</p>
                    </li>
                    <li>
                        <h4><span><i className="fa fa-map-marker" aria-hidden="true"></i></span></h4>
                        <p>Plot no. 458, Opp. Saraswati Enclave, Sector-37, Gurugram, Haryana 122001</p>
                    </li>
                    <li>
                        <a href="#" target="_blank" ><span><i className="fa fa-envelope" aria-hidden="true"></i></span></a>
                        <a href="#" target="_blank" ><span><i className="fa fa-instagram" aria-hidden="true"></i></span></a>
                        <a href="#" target="_blank" ><span><i className="fa fa-facebook-square" aria-hidden="true"></i></span></a>
                        <a href="#" target="_blank" ><span><i className="fa fa-twitter-square" aria-hidden="true"></i></span></a>
                        <a href="#" target="_blank" ><span><i className="fa fa-linkedin-square" aria-hidden="true"></i></span></a>
                    </li>
                </ul>
            </div>
            {/* <div className="left-footer"> */}
                {/* <h4><span><i className="fa fa-phone" aria-hidden="true"></i></span></h4>
                <p> 0124-2334639, 9310827822, 9350827821</p>

                <h4><span><i className="fa fa-map-marker" aria-hidden="true"></i></span></h4>
                <p>Plot no. 458, Opp. Saraswati Enclave, Sector-37, Gurugram, Haryana 122001</p>

                <a href="#" target="_blank" ><span><i className="fa fa-envelope" aria-hidden="true"></i></span></a>
                <a href="#" target="_blank" ><span><i className="fa fa-instagram" aria-hidden="true"></i></span></a>
                <a href="#" target="_blank" ><span><i className="fa fa-facebook-square" aria-hidden="true"></i></span></a>
                <a href="#" target="_blank" ><span><i className="fa fa-twitter-square" aria-hidden="true"></i></span></a>
                <a href="#" target="_blank" ><span><i className="fa fa-linkedin-square" aria-hidden="true"></i></span></a> */}
                {/* <h4>Neelkanth Roadways Corporation of India</h4>
                <p><i className="fa fa-phone" aria-hidden="true"></i> &nbsp; 0124-2334639, 9310827822, 9350827821</p>
                <p className="showDesktop">
                    <i className="fa fa-map-marker" aria-hidden="true"></i> &nbsp;
                    Plot no. 458, Opp. Saraswati Enclave, Sector-37,
                    <br />
                    &nbsp; &nbsp; &nbsp;Gurugram, Haryana 122001
                </p>
                <p className="showMobile">
                    <i className="fa fa-map-marker" aria-hidden="true"></i> &nbsp;
                    Plot no. 458, Opp. Saraswati Enclave,<br/>
                    &nbsp; &nbsp; &nbsp;Sector-37, Gurugram, Haryana 122001
                </p>
                <div className="footer-social-media-icons">&nbsp; &nbsp; &nbsp;
                    <a href="#" target="_blank" ><span><i className="fa fa-envelope" aria-hidden="true"></i></span></a>
                    <a href="#" target="_blank" ><span><i className="fa fa-instagram" aria-hidden="true"></i></span></a>
                    <a href="#" target="_blank" ><span><i className="fa fa-facebook-square" aria-hidden="true"></i></span></a>
                    <a href="#" target="_blank" ><span><i className="fa fa-twitter-square" aria-hidden="true"></i></span></a>
                    <a href="#" target="_blank" ><span><i className="fa fa-linkedin-square" aria-hidden="true"></i></span></a>
                </div>
            </div> */}
            {/* <div className="right-footer">
                <ul>
                    <li><p>Services</p></li>
                    <li><a href="/express-distribution">Express Distribution</a></li>
                    <li><a href="/inventory-management">3PL Inventory Management</a></li>
                    <li><a href="/reverse-logistics">Reverse Logistics</a></li>
                    <li><a href="/warehousing-distribution">Warehousing and Distribution</a></li>
                </ul>
                <ul>
                    <li><p>Industries</p></li>
                    <li><a href="/automotive">Automotive</a></li>
                    <li><a href="/packaging">Packaging</a></li>
                    <li><a href="/auto-part">Auto Part</a></li>
                </ul>
                <ul>
                    <li><p>Quick Links</p></li>
                    <li><a href="/about-us">About Us</a></li>
                    <li><a href="/connectivity">Network</a></li>
                    <li><a href="/contact-us">Contact Us</a></li>
                    <li><a href="/send-quote">Get Quote</a></li>
                </ul>
                <div className="cl"></div>
            </div> */}
            {/* <div className="cl"></div> */}
        </section>
    )
}

export default Footer;