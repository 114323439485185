import React, { useEffect } from "react";
import express_disrtibution from "../../assets/images/express_distribution.jpg";
import '../../assets/css/style.css';
import Header from "../Common/Header";
import Footer from "../Common/Footer";

function ExpressDistribution(props) {

    useEffect(() => {
        document.title = "Express Distribution - Neelkanth Roadways"
    }, [])

    return (
        <React.Fragment>
            <Header />
            <section className="logic-provider-section service-section">
                <div className="content-left">
                    <h4>Express Distribution</h4>
                    <span>
                        The pioneer of express distribution in India, we continue to innovate new offerings to address demands for express logistics in India. We ensure time-definite deliveries through our robust network supported by 'Hub and Spoke' model, which is intended to minimize the distance travelled and strategic management of logistics. Our fleet of over 7500 containerised vehicles covers the entire country proving a comprehensive range of fast, efficient & express transport and logistics services. As long as there's a road to it, there isn't a place in this country we can't deliver to or pick-up from!
                        This offering enables 'Just in Time' and lean supply chain requirements of the customers. This service also allows customers to plan their in transit inventory – an important input for advanced materials and distribution requirements planning. We fully utilize our infrastructure, technology resources, research & development, network, fleet and our ecosystem of partners to provide an unrivalled express logistics experience in India.
                    </span>
                    {/* <span>This door-to-door pickup and delivery service is backed by 54 state-of-the-art air hubs. Air cargo requires a careful handling due to its subtle and time sensitive nature. A dedicated team of specialized air freight experts man these hubs 24x7. This team has expertise and experience in completing the various documentation formalities required for different product categories across different airlines travelling to different states.</span> */}
                    <div className="span-text">
                        <h4>Key features</h4>
                        <ul className="list-content">
                            <li>Fastest transit-time in the industry</li>
                            <li>Largest Supply Chain Network</li>
                            <li>Guaranteed On-time Door to Door Delivery</li>
                            <li>State-of-the-art Hub & Spoke Model</li>
                            <li>Multi-modal freight services with varied customized solutions</li>
                            <li>24 x 7, 365 days a year operations</li>
                        </ul>
                    </div>
                </div>
                <div className="image-right">
                    <img src={express_disrtibution} alt="" />
                </div>
            </section>
            <Footer />
        </React.Fragment>
    );
}

export default ExpressDistribution;