import React, { useEffect } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import '../../assets/css/style.css';
import team1 from "../../assets/images/team1.jpg"
import team2 from "../../assets/images/team2.jpg"
import team3 from "../../assets/images/team3.jpg"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import express_disrtibution from "../../assets/images/express_distribution.jpg";
import Container from '@material-ui/core/Container';

function About(props) {

    useEffect(() => {
        document.title = "About Us - Neelkanth Roadways"
    }, [])

    const aboutSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        centerMode: true,
        slidesToScroll: 3,
        items: 3,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    centerMode: false,
                    items: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                    items: 1
                }
            }
        ]
    };

    return (
        <React.Fragment>
            <Header />
            <section className="service-section gradient-blue">
                <Container >

                    <div className="about-section">
                        <h2 style={{ textAlign: "center" }}>About Us</h2>
                        <span>
                            Set up as a One-truck activity in 1992, Neelkanth rapidly shifted gears to grow into one of the Reliable surface logistics and road transportation companies in Indian Auto Industries.

                        <br></br><br></br>With a network that spans 22 stations countrywide and a workforce of over 200 highly trained people, Neelkanth is reputed for its prompt, reliable service and strict adherence to delivery schedules that facilitate movement of cargo worth Rs.100 Million annually. The Company today has more than 100+ satisfied customers in Auto & Textile Industries, which include multinationals, public and private sector organizations and small-scale industries as well as the trading community.

                        <br></br><br></br>The Company is steadily delivering on its promise of providing the swift service Investment in IT and state-of-the art tracking systems, coupled with containerization of its entire fleet, help Neelkanth to maintain distinctive edge over competition while simultaneously offering enhanced and value-added services to the industry.
                    </span>
                    </div>
                </Container>

                {/* <h2 style={{ textAlign: "center" }}>Our Team</h2> */}
                {/* <div className="row">
                    <Slider {...aboutSettings}>
                        <div className="column">
                            <div className="card">
                                <img src={team1} alt="" style={{ width: '100%' }} />
                                <div className="about-container">
                                    <h2>Jane Doe</h2>
                                    <p className="team-title">CEO & Founder</p>
                                    <p>Some text that describes me lorem ipsum ipsum lorem.</p>
                                    <p>jane@example.com</p>
                                    <p><button className="contact-button">Contact</button></p>
                                </div>
                            </div>
                        </div>

                        <div className="column">
                            <div className="card">
                                <img src={team2} alt="" style={{ width: '100%' }} />
                                <div className="about-container">
                                    <h2>Mike Ross</h2>
                                    <p className="team-title">Art Director</p>
                                    <p>Some text that describes me lorem ipsum ipsum lorem.</p>
                                    <p>mike@example.com</p>
                                    <p><button className="contact-button">Contact</button></p>
                                </div>
                            </div>
                        </div>

                        <div className="column">
                            <div className="card">
                                <img src={team3} alt="" style={{ width: '100%' }} />
                                <div className="about-container">
                                    <h2>John Doe</h2>
                                    <p className="team-title">Designer</p>
                                    <p>Some text that describes me lorem ipsum ipsum lorem.</p>
                                    <p>john@example.com</p>
                                    <p><button className="contact-button">Contact</button></p>
                                </div>
                            </div>
                        </div>

                        <div className="column">
                            <div className="card">
                                <img src={team3} alt="" style={{ width: '100%' }} />
                                <div className="about-container">
                                    <h2>John Doe</h2>
                                    <p className="team-title">Designer</p>
                                    <p>Some text that describes me lorem ipsum ipsum lorem.</p>
                                    <p>john@example.com</p>
                                    <p><button className="contact-button">Contact</button></p>
                                </div>
                            </div>
                        </div>

                        <div className="column">
                            <div className="card">
                                <img src={team3} alt="" style={{ width: '100%' }} />
                                <div className="about-container">
                                    <h2>John Doe</h2>
                                    <p className="team-title">Designer</p>
                                    <p>Some text that describes me lorem ipsum ipsum lorem.</p>
                                    <p>john@example.com</p>
                                    <p><button className="contact-button">Contact</button></p>
                                </div>
                            </div>
                        </div>

                        <div className="column">
                            <div className="card">
                                <img src={team3} alt="" style={{ width: '100%' }} />
                                <div className="about-container">
                                    <h2>John Doe</h2>
                                    <p className="team-title">Designer</p>
                                    <p>Some text that describes me lorem ipsum ipsum lorem.</p>
                                    <p>john@example.com</p>
                                    <p><button className="contact-button">Contact</button></p>
                                </div>
                            </div>
                        </div>
                    </Slider> */}
                {/* </div> */}
            </section>
            <Footer />
        </React.Fragment>
    );
}

export default About;