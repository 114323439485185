import React, { useEffect } from "react";
import vendorManagement from "../../assets/images/wareDis.webp";
import '../../assets/css/style.css';
import Header from "../Common/Header";
import Footer from "../Common/Footer";

function WarehosingDistribution(props) {

    useEffect(() => {
        document.title = "Warehousing Distribution - Neelkanth Roadways"
    }, [])

    return (
        <React.Fragment>
            <Header />
            <section class="logic-provider-section service-section">
                <div class="content-left">
                    <h4>Warehousing and Distribution</h4>
                    <span>
                        Providing end to end solutions, we also provide warehousing services. We have a large network of warehouses placed perfectly to speed up logistics.
                    </span>
                    <div class="span-text">
                        <h4>Salient Features</h4>
                        <ul class="list-content">
                            <li>Large and clean warehouses using latest warehousing technologies</li>
                            <li>Automated handling of consignments</li>
                            <li>Highly configurable lot handling and shelf life handling (for FMCG and food items)</li>
                        </ul>
                    </div>
                </div>
                <div class="image-right">
                    <img src={vendorManagement} alt="" />
                </div>
            </section>
            <Footer />
        </React.Fragment>
    );
}

export default WarehosingDistribution;