import React from "react";
import logo from "../../assets/images/neelkanthLogo.png";
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Icon from '@material-ui/core/Icon';

// import logo from "../../assets/images/logo1.svg";

function Header(props) {

    const [sidebar, openSidebar] = React.useState(false);
    const [servicesSubmenu, servicesSubmeuOpen] = React.useState(false);
    const [industriesSubmenu, industriesSubmeuOpen] = React.useState(false);
    const [networksSubmenu, networksSubmeuOpen] = React.useState(false);
    const anchors = React.useRef(null);

    function sidebarOpen() {
        openSidebar(!sidebar);
    }
    function sidebarClosed(event) {
        if (anchors.current && anchors.current.contains(event.target)) {
            return;
        }
        openSidebar(false);
    }
    function dropdownServicesOpen() {
        servicesSubmeuOpen(!servicesSubmenu);
        industriesSubmeuOpen(false);
        networksSubmeuOpen(false);
    }
    function dropdownIndustriesOpen() {
        servicesSubmeuOpen(false);
        industriesSubmeuOpen(!industriesSubmenu);
        networksSubmeuOpen(false);
    }
    function dropdownNetworksOpen() {
        servicesSubmeuOpen(false);
        industriesSubmeuOpen(false);
        networksSubmeuOpen(!networksSubmenu);
    }
    function closeMenufunction() {
        openSidebar(false);
        servicesSubmeuOpen(false);
        industriesSubmeuOpen(false);
        networksSubmeuOpen(false);
    }
    return (
        <div className="header gradient-blue">
            <div className="logo">
                <a href="/"><img src={logo} alt="" /></a>
            </div>
            <div className="menu-icon" onClick={sidebarOpen} ref={anchors}>
                <i className="fa fa-bars" aria-hidden="true"></i>
            </div>
            {/* <ClickAwayListener onClickAway={sidebarClosed}>
                <div anchore1={anchors.current} className={'overlay' + ' ' + (sidebar ? 'overlay-open' : '')} />
            </ClickAwayListener> */}

            <ClickAwayListener onClickAway={sidebarClosed} >

                <ul className={sidebar ? 'open' : null}>
                    <li className="closeMenu">
                        <i className="fa fa-times" onClick={closeMenufunction} />
                    </li>
                    <li>
                        <a href="/about-us">About Us</a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" onClick={dropdownServicesOpen}>Services
                        <span className="arrow-down" ><i className={servicesSubmenu ? "fa fa-caret-up" : "fa fa-caret-down"} /></span>
                        </a>
                        <div className={'dropdown' + ' ' + (servicesSubmenu ? 'dropdown-open' : '')}>
                            <ul>
                                <li><a href="/express-distribution">Express Distribution</a></li>
                                <li><a href="/inventory-management">3PL Inventory Management</a></li>
                                <li><a href="/reverse-logistics">Reverse Logistics</a></li>
                                <li><a href="/warehousing-distribution">Warehousing and Distribution</a></li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <a href="javascript:void(0)" onClick={dropdownIndustriesOpen}>Industries
                        <span className="arrow-down" onClick={dropdownIndustriesOpen}><i className={industriesSubmenu ? "fa fa-caret-up" : "fa fa-caret-down"} /></span>
                        </a>
                        <div className={'dropdown' + ' ' + (industriesSubmenu ? 'dropdown-open' : '')}>
                            <ul>
                                <li><a href="/automotive">Automotive</a></li>
                                <li><a href="/packaging">Packaging</a></li>
                                <li><a href="/auto-part">Auto Part</a></li>
                            </ul>
                        </div>
                    </li>
                    {/* <li>
                    <a href="/">Support</a>
                </li> */}
                    <li>
                        <a href="/connectivity">Network</a>
                    </li>
                    <li>
                        <a href="/gst-info">GST Info</a>
                    </li>
                    <li>
                        <a href="/contact-us">Contact Us</a>
                    </li>
                    <li className="get-quote">
                        <a href="/send-quote">Get Quote</a>
                    </li>
                    {/* <li>
                    <a href="/">GST Support</a>
                    <span className="arrow-down" onClick={dropdownOpen}><i className="fa fa-caret-down" /></span>
                    <div className="dropdown">
                        <ul>
                            <li><a href="/">Create GST Invoice</a></li>
                            <li><a href="/">Download GST Invoice</a></li>
                            <li><a href="/">GST advantage with Safexpress</a></li>
                            <li><a href="/">Call back request</a></li>
                        </ul>
                    </div>
                </li> */}
                </ul>
            </ClickAwayListener>
        </div>
    )
}

export default Header;